import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from './logo-grandecremerie.svg';
import styles from './Logo.module.scss';

interface Props {
	className?: string;
}

function Logo({ className }: Props) {
	return (
		<Link
			to="/"
			title="Aller à l'accueil"
			className={[styles.link, className].filter(Boolean).join(' ')}
		>
			<LogoSVG className={styles.logo} />
		</Link>
	);
}

export default Logo;
