import React from 'react';
import { Link } from 'react-router-dom';
import { french } from '../../../utils/french';
import { photosActualitesSizes } from '../../../utils/imgSizes';
import Button from '../../Button/Button';
import Img from '../../Img/Img';
import styles from './ActualiteSingle.module.scss';

export interface Props {
	className?: string;
	titre: string;
	contenu: string;
	imageSrc: string;
	url?: string;
}

function ActualiteSingle({ className, titre, contenu, imageSrc, url }: Props) {
	const isLocaleLink =
		url && (url.charAt(0) === '/' || url.includes('.grandecremerie.fr'));

	const img = (
		<Img
			src={imageSrc}
			alt={titre}
			fallback="jpg"
			className={styles.imgComponent}
			otherSizes={photosActualitesSizes}
			mediaQueries={[
				{ breakpoint: '<=mobile', width: 'calc(100vw - 80px)' },
				{ breakpoint: '<laptop', width: 'calc(50vw - 140px)' },
				{ width: 315 },
			]}
		/>
	);

	const btn = <Button className={styles.button}>Je découvre</Button>;

	const aProps = { target: '_blank', rel: 'noreferrer noopener' };

	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			{imageSrc ? (
				url ? (
					isLocaleLink ? (
						<Link to={url} className={styles.img}>
							{img}
						</Link>
					) : (
						<a href={url} className={styles.img} {...aProps}>
							{img}
						</a>
					)
				) : (
					<div className={styles.img}>{img}</div>
				)
			) : (
				<svg
					viewBox="0 0 16 9"
					className={[styles.img, styles.skeleton].join(' ')}
				/>
			)}

			<div className={styles.text}>
				<p
					className={[styles.titre, !titre && styles.skeleton]
						.filter(Boolean)
						.join(' ')}
				>
					{french(titre)}
				</p>

				<p
					className={[styles.contenu, !contenu && styles.skeleton]
						.filter(Boolean)
						.join(' ')}
				>
					{french(contenu)}
				</p>

				{url &&
					(isLocaleLink ? (
						<Link to={url}>{btn}</Link>
					) : (
						<a href={url} {...aProps}>
							{btn}
						</a>
					))}
			</div>
		</div>
	);
}

export default ActualiteSingle;
